import React, { useEffect, useRef, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const OurTeam = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };
  const handleMouseEnter5 = () => {
    setIsHovered5(true);
  };

  const handleMouseLeave5 = () => {
    setIsHovered5(false);
  };

  // useEffect(() => {
  //   const scrollToAboutFourComponent = () => {
  //     const aboutFourComponent = document.getElementById('teamId');
  //     if (aboutFourComponent) {
  //       aboutFourComponent.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   };

  //   scrollToAboutFourComponent();
  // }, []);

  return (
    <>
      <div className="w-[90%] mx-auto py-[10%] md:py-[4%]" id="team">
        <div>
          <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center uppercase">
            Our Team
          </button>
        </div>

        <div className="flex flex-col md:flex-row  gap-4 flex-wrap py-5 md:py-10">
          <div className="w-[100%] md:w-[32%] h-auto md:h-[300px] pr-0 md:pr-[10%] mb-5 md:mb-0">
            <h1 className="font-medium text-[#110F42] text-4xl md:text-5xl mb-3  ">
              Meet Our Team
            </h1>
            <p className="text-zinc-500 my-5">
              Our team brings together leading talent from the crypto and
              creative communities, to the cultural realm. We’re a diverse,
              close-knit team on an adventure to build something enduring, while
              learning something new, every day.
            </p>
          </div>
          <div className="team-main-bx  w-[100%] md:w-[32%] h-[300px]">
            <div
              className={`w-100% md-w-[32%] h-[100%] flex flex-col justify-between p-5 bg-white rounded-md shadow-md `}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <img
                src="/images/mudit.png"
                alt=""
                className={'w-[120px] h-[120px] '}
              />
              {/* ${
                  isHovered1
                    ? "scale-125 transition-transform duration-300"
                    : ""
                }` */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                    Mudit Marda
                  </p>
                  <p className="text-base text-[#6A6D77]">
                    Chief Executive Officer
                  </p>
                </div>
                <div>
                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl"
                />
                </div>
                
              </div>
            </div>

            <div className="team-hover-bx">
              <div className="team-first-flex-bx">
                <div className="left-team-img">
                  <img src="/images/mudit.png" alt="" />
                </div>
                <div className="right-team-info">
                  <h6>Mudit Marda</h6>
                  <p>Chief Executive Officer</p>
                </div>
                <div>
                  <a href="https://www.linkedin.com/in/muditmarda"  target="_blank">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl team-icon"
                />
                </a>
                </div>
                {/* <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-3xl team-icon"
                /> */}
              </div>

              <p className="team-para">
                Mudit is a 2x crypto founder, blockchain technology specialist &
                web3 exponent with expertise in product building and growth. He
                founded DRIFE as the CTO.
              </p>

            
            </div>
          </div>

          <div className="team-main-bx  w-[100%] md:w-[32%] h-[300px]">
            <div
              className={`w-100% md-w-[32%] h-[100%] flex flex-col justify-between p-5 bg-white rounded-md shadow-md `}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <img
                src="/images/rahul.png"
                alt=""
                className={'w-[120px] h-[120px] '}
              />
              {/* ${
                  isHovered1
                    ? "scale-125 transition-transform duration-300"
                    : ""
                }` */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                  Jake Fraser
                  </p>
                  <p className="text-base text-[#6A6D77]">
                  Chief Operations Officer
                  </p>
                </div>

                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl"
                />
              </div>
            </div>

            <div className="team-hover-bx">
              <div className="team-first-flex-bx">
                <div className="left-team-img">
                  <img src="/images/rahul.png" alt="" />
                </div>
                <div className="right-team-info">
                  <h6>Jake Fraser</h6>
                  <p>Chief Operations Officer</p>
                </div>
                <div>
                <a href="https://www.linkedin.com/in/jakefraser1"  target="_blank">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-3xl team-icon"
                />
                </a>
                </div>
                
              </div>

              <p className="team-para">
              Jake has 10+ years of business development, partnership building,
              sales, business strategy and growth experience across a variety
              of Web2 & Web3 industries.
              </p>

            
            </div>
          </div>



          <div className="team-main-bx  w-[100%] md:w-[32%] h-[300px]">
            <div
              className={`w-100% md-w-[32%] h-[100%] flex flex-col justify-between p-5 bg-white rounded-md shadow-md `}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <img
                src="/images/noelle.png"
                alt=""
                className={'w-[120px] h-[120px] '}
              />
              {/* ${
                  isHovered1
                    ? "scale-125 transition-transform duration-300"
                    : ""
                }` */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                  Purnima Agrawal
                  </p>
                  <p className="text-base text-[#6A6D77]">
                  Chief Technology Officer
                  </p>
                </div>

                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl"
                />
              </div>
            </div>

            <div className="team-hover-bx">
              <div className="team-first-flex-bx">
                <div className="left-team-img">
                  <img src="/images/noelle.png" alt="" />
                </div>
                <div className="right-team-info">
                  <h6>Purnima Agrawal</h6>
                  <p>Chief Technology Officer</p>
                </div>
                <div>
                  <a  href="https://www.linkedin.com/in/purnimaagrawal"  target="_blank">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-3xl team-icon"
                />
                </a>
                </div>
               
              </div>

              <p className="team-para">
                Purnima is the perfect balance of a corporate leader,
                a crypto degen, and technical expert with 10+ years
                of experience in the web3 technology space.
              </p>

            
            </div>
          </div>


          <div className="team-main-bx  w-[100%] md:w-[32%] h-[300px]">
            <div
              className={`w-100% md-w-[32%] h-[100%] flex flex-col justify-between p-5 bg-white rounded-md shadow-md `}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <img
                src="/images/purnima.png"
                alt=""
                className={'w-[120px] h-[120px] '}
              />
              {/* ${
                  isHovered1
                    ? "scale-125 transition-transform duration-300"
                    : ""
                }` */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                  Noëlle Gahan
                  </p>
                  <p className="text-base text-[#6A6D77]">
                  Chief Marketing Officer
                  </p>
                </div>

                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl"
                />
              </div>
            </div>

            <div className="team-hover-bx">
              <div className="team-first-flex-bx">
                <div className="left-team-img">
                  <img src="/images/purnima.png" alt="" />
                </div>
                <div className="right-team-info">
                  <h6>Noëlle Gahan</h6>
                  <p>Chief Marketing Officer</p>
                </div>
                <div>
                   <a href="https://www.linkedin.com/in/noelle-gahan"  target="_blank"> 
              <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-3xl team-icon"
                />
                </a>
                </div>
                
              </div>

              <p className="team-para">
              Noëlle brings two decades of experience in emerging
              tech marketing leveraging compelling storytelling,
              focused PR and growth tactics to elevate brand equity. 
              </p>

            
            </div>
          </div>


          <div className="team-main-bx  w-[100%] md:w-[32%] h-[300px]">
            <div
              className={`w-100% md-w-[32%] h-[100%] flex flex-col justify-between p-5 bg-white rounded-md shadow-md `}
              onMouseEnter={handleMouseEnter1}
              onMouseLeave={handleMouseLeave1}
            >
              <img
                src="/images/adeshina.png"
                alt=""
                className={'w-[120px] h-[120px] '}
              />
              {/* ${
                  isHovered1
                    ? "scale-125 transition-transform duration-300"
                    : ""
                }` */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                  Adeshina Adedapo
                  </p>
                  <p className="text-base text-[#6A6D77]">
                    Design Lead
                  </p>
                </div>

                <FontAwesomeIcon
                  icon={faLinkedin}
                  // style={{ color: `${isHovered1 ? "#5300CC" : "#667085"}` }}
                  className="text-3xl"
                />
              </div>
            </div>

            <div className="team-hover-bx">
              <div className="team-first-flex-bx">
                <div className="left-team-img">
                  <img src="/images/adeshina.png" alt="" />
                </div>
                <div className="right-team-info">
                  <h6>Adeshina Adedapo</h6>
                  <p>Design Lead</p>
                </div>
                <div>
                  <a  href="https://www.linkedin.com/in/adedapoadeshina"  target="_blank">
                  <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-3xl team-icon"
                />
                  </a>
                </div>
                
              </div>

              <p className="team-para">
              Adedapo has 7+ years of experience and is passionate 
              about creating user-centric, delightful and human experiences. 
              He has worked with Moove Africa, Enyata, NIBSS, FSI amongst other companies.
              </p>

            
            </div>
          </div>

          {/* <div
            className={`w-[100%] md:w-[32%] h-[300px] flex flex-col justify-between p-5 bg-white rounded-md shadow-md ${
              isHovered2 ? "linkedin-hover" : ""
            }`}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            <img
              src="/images/neel.png"
              alt=""
              className={`w-[150px] h-[150px] ${
                isHovered2 ? "scale-125 transition-transform duration-300" : ""
              }`}
            />
            <div className="flex flex-row justify-between items-center">
              <div>
                <p className="font-bold text-xl text-[#110F42] mb-2">
                  Jake Fraser
                </p>
                <p className="text-base text-[#6A6D77]">
                  Chief Operations Officer
                </p>
              </div>

              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ color: `${isHovered2 ? "#5300CC" : "#667085"}` }}
                className="text-3xl"
              />
            </div>
          </div> */}
         
        </div>
        {/* <div>
          <br />
          <br />
          <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center uppercase">
            OUR ADVISORY BOARD
          </button>
        </div> */}
      </div>
    </>
  );
};

export default OurTeam;
