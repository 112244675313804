import React from 'react'

const DexaToken = () => {
  return (
    <>
       <section className="dexa-token-comp" id="vision">

        <div className="common-container">
          <div className="global-market-size-info-flex-bx contact-text-flex-bx dexa-token-info-flex">
            <h3>
              All Things <span>$DEXA</span> Token
            </h3>
 
            <p>
              $DEXA is a type of utility and governance token that can be used
              within the DexAuction ecosystem for a variety of use cases right
              from participation in governance decisions to paying transaction
              fees & much more.
            </p>
          </div>

          <div className="token-img token-img2">
            <img src="/images/Tokens.png" alt="" />
          </div>

          <p className="last-center-text">
            We’re committed to ensuring that DEXA tokens not only reflect our
            mission but also resonate with our users as they navigate the
            decentralised financial frontier.
          </p>
        </div>
      </section>
    </>
  )
}

export default DexaToken
