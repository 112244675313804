import React from 'react'
import {DexaDataOne} from './DexaData'
import {DexaDataTwo} from './DexaDataTwo'

const DexaInfrastructure = () => {

    
  return (
    <>

    <section className="infrastructure-comp" id='infrastructure'>
      <div className="common-container">

      <div className="common-container">
          <div className="mb-5 rdex-round-text">
            <button
              class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
              id="research"
            >
              DEXA INFRASRUCTURE
            </button>
          </div>

          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex rdex-content-bx infrastr-cont-bx  reimag-content-bx">
            <h3>Inspired by Traditional Finance, Improved for Web3! </h3>
          </div>

          <div className="infrastrutre-grid-main-bx">
            <div className="lin-dot"></div>
            <div className="lin-dot2 "></div>
            <div className="lin-dot3"></div>



                {DexaDataOne.map((curElem,id) => {
                  return(
                    <>
                    <div className="dexa-infra-main-bx" key={id}>

                      <div className="dexa-infra-bx">
                      <p> {curElem.text} </p>

                      <button>
                        {curElem.button}
                      </button>
                      
                    </div>


                
                    
                    </div>
                    </>
                  )
                })}


{DexaDataTwo.map((curData,id) => {
                      return (
                        <>
                        <div className="dexa-data-infra-bx">

                          <div className="dexa-data-info-bx">
                            <img src={curData.iconOne} alt="" />
                            <h6> {curData.textOne} </h6>
                          </div>
                          <div className="dexa-data-info-bx">
                            <img src={curData.iconTwo} alt="" />
                            <h6> {curData.textTwo} </h6>
                          </div>
                          <div className="dexa-data-info-bx">
                            <img src={curData.iconThree} alt="" />
                            <h6> {curData.textThree} </h6>
                          </div>
                          <div className="dexa-data-info-bx">
                            <img src={curData.iconFour} alt="" />
                            <h6> {curData.textFour} </h6>
                          </div>
                          
                        </div>
                        </>
                      )
                        
                      
                    })}


<div className="block-chain-bx">
                  <p>DEXA Blockchain Indexer & Auction Engine & API</p>
                </div>

          </div>

          <div className="block-chain-bx block-chain-bx2">
            <p>Multi-chain Blockchain Networks</p>
          </div>

          </div>
        
      </div>
    </section>
      
    </>
  )
}

export default DexaInfrastructure
