import React, { useEffect } from "react";
import { useLocation } from "react-router";

import AuthorizedPageCard from "../../components/ui/AuthorizedPageCard";
import Banner from "./Banner";
import HowItWork from "./HowItWork";
import Investors from "./Investors";
import OurBenefits from "./OurBenefits";
import Subscribe from "./Subscribe";
import TokenComponent from "./TokenComponent";
import WhyDexAuction from "./WhyDexAuction";

const Home = () => {
  return (
    <AuthorizedPageCard>
      <Banner />
      <WhyDexAuction />
      <HowItWork />
      <OurBenefits />
       <TokenComponent />
      <Investors />
      <Subscribe />
    </AuthorizedPageCard>
  );
};

export default Home;
