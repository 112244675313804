import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const Subscribe = () => {
  const [email, setEmail] = useState("");

  const handleSubscribe = () => {
    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/dexAuction/subscribe`,
      headers: {
        "Content-Type": "application/json",
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Successfuly Subscribed to Dex Auction");
          setEmail("");
        }
      })
      .catch((error) => {
        toast.error("failed to subscribe");
      });
  };
  return (
    <div
      className=" bg-cover bg-center py-[20%] md:py-[5%] text-white "
      style={{
        backgroundImage: `url('/images/subbg.png')`,
      }}
    >
      <div className="w-[90%] mx-auto">
        <div className="w-[100%] md:w-[60%] ">
          <div className=" text-stone-200 text-4xl md:text-6xl font-medium leading-10 mb-8">
            Subscribe to be in touch*
          </div>
          <div className=" text-stone-200 text-xl font-normal mb-5 md:mb-0  leading-7">
            Join our community today and stay up-to-date with the latest
            industry news and trends, valuable insights to help you succeed, as
            well as updates on our products and services.
          </div>
        </div>
        {/* subscribe   */}
        <div className="w-[80%]">
          <p className="font-semibold  mt-5">your email*</p>
          <div className="flex flex-row justify-center items-center gap-5">
            <div className="w-[80%] ">
              <input
                className=" w-[100%] px-3 py-3  bg-[#5300CC]  shadow-sm focus:outline-none  "
                placeholder="e.g, name@example.com"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="w-[20%]">
              <button
                onClick={() => {
                  handleSubscribe();
                }}
                className="text-white  border border-[#6C26D3] py-2 px-5 rounded-lg inline-flex items-center "
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="w-full md:w-[90%] bg-[#6C26D3] h-[2px] mb-3"></div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
