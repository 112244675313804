import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Footer/Footer";

const AuthorizedPageCard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={` w-screen`}>
      <div className="h-screen  text-gray-600 w-screen">
        <div className="flex flex-row w-screen">
          <div className="bg-slate-100 overflow-y-scroll  md:ml-[0px]   w-screen h-screen ">
            <Navbar />
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizedPageCard;
