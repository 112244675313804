import React from "react";

const IroComp = () => {
  return (
    <>
      <section className="iro-comp" id="Iro">
        <div className="common-container">
          <div className="mb-5 iro-round-text">
            <button
              class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
              id="research"
            >
              IRO LAUNCHPAD
            </button>
          </div>

          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex iro-content-bx reimag-content-bx">
            <h3>
              Introducing IRO <br />
              (Initial RWA Offering)
            </h3>

            <p>
              The IRO is designed as the book-building style auctions employed
              in the Initial Public Offerings (IPOs) where companies offer
              primary issuance of their shares to retail investors raising
              a significant amount of capital.
            </p>
          </div>

          <div className="iro-grid-bx iro-grid-bx-main">
            <div className="left-iro-img">
              <img src="/productimages/iro-img.png" alt="" />
            </div>

            <div className="right-iro-content-flex-bx">
              <div className="full-width-iro-content-bx">
                <p>
                  Our IRO Launchpad solution can be integrated into your
                  applications to allow your users and investors to place bids
                  in the Primary Sale of Real World Assets being tokenised. 
                </p>
                <p className="mt-5">
                Use
                  our IRO Launchpad SDK to supercharge your Tokenised RWAs’
                  Primary Sale with fair launch custom auctions and enable
                </p>
                
              </div>

              <div className="iro-grid-bx iro-grid-bx2">
              <div className="iro-mini-bx">
                <div className="iro-mini-img">
                    <img src="/productimages/iro-puz-img-1.png" alt="" />
                </div>
                <h5>Optimal  Price <br/> Discovery</h5>
              </div>

              <div className="iro-mini-bx iro-mini-bx2">
                <div className="iro-mini-img iro-mini-img2">
                    <img src="/productimages/iro-puz-img-2.png" alt="" />
                </div>
                <h5>Fair allocation to <br/> investors</h5>
              </div>
              
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IroComp;
