import Getstarted from "../Home/Getstarted";
import Subscribe from "../Home/Subscribe";
import DexaToken from "./DexaToken";
import Token from "./Token";
import AuthorizedPageCard from "../../components/ui/AuthorizedPageCard";

const TokenDEXA = () => {
    return (
      <AuthorizedPageCard>
                  <DexaToken />
                  <Token />
                  <Getstarted />
               <Subscribe />
      </AuthorizedPageCard>
    );
  };
  
  export default TokenDEXA;