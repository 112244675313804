import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Networksuported = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1125  },
          items: 8,
          slidesToSlide: 1,
        },
       
        tablet: {
          breakpoint: { max: 1125, min: 760 },
          items: 5,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 700, min: 320 },
          items: 2,
          slidesToSlide: 1,
        },
      };

    const Data = [

        {
            
            img : '/productimages/solana.png'
        },
        {
            
            img : '/productimages/ethereum.png'
        },
        {
            
            img : '/productimages/polygon.png'
        },
        {
            
            img : '/productimages/Chain.png'
        },
        {
            
            img : '/productimages/Chain (1).png'
        },
        {
            
            img : '/productimages/Chain (2).png'
        },
        {
            
            img : '/productimages/Chain (3).png'
        },
        {
            
            img : '/productimages/Chain (4).png'
        },
        
        
    ]
    
  return (
    <>
      <section className="rdex-comp" id="networksuprted">
        <div className="common-container">
          <div className="mb-5 rdex-round-text">
            <button
              class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
              id="research"
            >
              NETWORKS SUPPORTED
            </button>
          </div>

          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex rdex-content-bx  reimag-content-bx">
            <h3>
              Multi-chain, <br /> Multi-standard.{" "}
            </h3>

            <p>
              Our tools and infrastructure are designed to seamlessly operate
              across multiple blockchain networks, ensuring compatibility with a
              wide range of fungible and non-fungible token standards.
            </p>
        </div>
          </div>

          <div className="slider-container">
          <Carousel
          swipeable={false}
          draggable={true}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          keyBoardControl={true}
          customTransition="transform 1000ms ease-in"
          transitionDuration={1000}
        >

            {Data.map((curData,id) => (

                    <div className="net-suport-bx" key={id}>
                        <img src={curData.img} alt="" />
                    </div>
                    
          
        ))}

        </Carousel>
        </div>
      </section>
    </>
  );
};

export default Networksuported;
