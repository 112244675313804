import React, { useState, useRef } from "react";
import AuthorizedPageCard from "../../components/ui/AuthorizedPageCard";
import axios from "axios";
import { toast } from "react-toastify";

import emailjs from "@emailjs/browser";
import { CircularProgress } from "@mui/material";
import Subscribe from "../Home/Subscribe";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    agree: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      body: `Name: ${formData.name}<br />Email: ${formData.email}<br />Message: ${formData.message}<br />`,
      email: "contact@dex.auction",
      subject: formData.subject,
    };

    const headers = {
      "Content-Type": "application/json",
      "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DEXAUCTION_DEV_API}/internal/email/send`,
        data,
        { headers }
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success("Thank you. We will get back to you soon");

        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
          agree: false,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const form = useRef();

  return (
    <AuthorizedPageCard>
      <div className="w-[90%] mx-auto pt-[70px]">
        <button
          type="submit"
          className="text-[#5300CC] font-semibold border border-[#5300CC] py-1.5 px-5 rounded-full inline-flex items-center mt-20 md:mt-10 "
        >
          Contact Us?
        </button>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 py-[5%]">
          <div className="">
            <div className="w-[100%] md:w-[80%] text-indigo-950 text-4xl md:text-5xl font-medium my-10">
              We’re here when you need us
            </div>
            <div className="w-full md:w-[60%]">
              {" "}
              <div className=" text-zinc-500 text-xs font-normal  uppercase leading-none mb-2 ">
                ADDRESS
              </div>
              <div className="text-indigo-950 text-base font-semibold  leading-snug">
                <p> Ground Floor,</p>
                <p className="mt-5">
                  The Sotheby Building,Rodney Village, Rodney Bay,Gros Islet,
                  Saint Lucia
                </p>
              </div>
              <div className=" text-zinc-500 text-xs font-normal  uppercase leading-none mt-5 mb-2">
                PHONE
              </div>
              <div className=" text-indigo-950 text-base font-semibold  leading-snug">
                +1 818-510-7935
              </div>
              <div className=" text-zinc-500 text-xs font-normal  uppercase leading-none mt-5 mb-2">
                Need Help?
              </div>
              <div className=" text-indigo-950 text-base font-semibold  underline leading-snug">
                contact@dex.auction
              </div>
            </div>
          </div>
          <div className="mt-10 md:mt-0">
            <div className=" text-zinc-500 text-xl font-medium  leading-7">
              Please leave us a message. Our team will contact you as soon as
              possible.
            </div>
            <div className=" text-zinc-500 text-md font-normal my-5 ">
              CONTACT FORM
            </div>

            <form ref={form} onSubmit={handleSubmit} className="w-full">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="text-zinc-500 text-md font-normal"
                >
                  Your Name*
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-blue-50 border-b border-gray-300 focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="text-zinc-500 text-md font-normal"
                >
                  Your Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-blue-50 border-b border-gray-300 focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="text-zinc-500 text-md font-normal"
                >
                  Subject*
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full bg-blue-50 border-b border-gray-300 focus:border-blue-500 focus:outline-none"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="text-zinc-500 text-md font-normal"
                >
                  Your Message*
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full bg-blue-50 border-b border-gray-300 focus:border-blue-500 focus:outline-none"
                  required
                ></textarea>
              </div>
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  checked={formData.agree}
                  onChange={handleChange}
                  className="mr-2 w-[50px] h-[50px] md:w-[30px] md:h-[30px]"
                />
                <label htmlFor="agree" className="text-sm">
                  Yes, I agree to receive occasional information from DexAuction
                  about their products and services or with other content that
                  may be of interest to me.
                </label>
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="inline-flex items-center  px-5 py-3 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-[#5300CC] border border-transparent border-solid rounded-md cursor-pointer select-none  "
                >
                  {!loading ? (
                    <div className="flex gap-2 justify-center items-center">
                      <p>Send</p>
                      <svg
                        className="w-4 h-4 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Subscribe />
    </AuthorizedPageCard>
  );
};

export default ContactUs;
