import React from "react";

const RdexComp = () => {
  return (
    <>
      <section className="rdex-comp" id="rdex">
        <div className="common-container">
          <div className="mb-5 rdex-round-text">
            <button
              class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
              id="research"
            >
              RDEX
            </button>
          </div>

          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex rdex-content-bx  reimag-content-bx">
            <h3>A DEX for RWAs</h3>

            <p>
              The RDEX is inspired by <b> Double auctions </b> and <b>Periodic Batch
              auctions </b>  across major stock exchanges, dark pools and government
              security markets.
            </p>
          </div>

          <div className="iro-grid-bx  iro-grid-bx-main rdex-grid">

          <div className="right-iro-content-flex-bx left-rdex-content">
              <div className="full-width-iro-content-bx">
                <p>
                Our RDEX solution can be integrated into your applications to allow your traders and investors to trade in the Secondary Markets of your Real World Assets. 

                </p>

                <p className="mt-5">
                Use our RDEX SDK to supercharge your Tokenized RWAs’ Secondary Sale with efficient trading and enable:
                </p>
                
              
                
              </div>

              <div className="iro-grid-bx iro-grid-bx2">
              <div className="iro-mini-bx rdex-mini-bx">
                <div className="iro-mini-img">
                    <img src="/productimages/iro-puz-img-1.png" alt="" />
                </div>
                <h5>Optimal  Price <br/> Discovery</h5>
              </div>

              <div className="iro-mini-bx rdex-mini-bx iro-mini-bx2">
                <div className="iro-mini-img iro-mini-img2">
                    <img src="/productimages/arrow-img.png" alt="" />
                </div>
                <h5>Low slippage and <br/> price impacts</h5>
              </div>
              
              </div>

            </div>

            <div className="right-rdex-img-bx">
                <img src="/productimages/dex-round-img.png" alt="" />
            </div>
            
          </div>
          
        </div>
      </section>
    </>
  );
};

export default RdexComp;
