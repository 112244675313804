import React from "react";

const NobelAunctions = () => {
  return (
    <>
      <section id="nobelPrize" className="nobel-aunctin-comp">
        <div className="common-container">
          <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center">
            NOBEL FOR AUCTIONS
          </button>

          <div className="nobel-prize-grid-main-bx">
            <div className="left-nobel-prize-bx">
              <div className="nobel-img-bx">
                <img src="/images/nobel-img.png" alt="" />
              </div>
              <div className="nobel-prize-info-bx">
                <h6>PAUL R. MILGROM, & ROBERT B. WILSON</h6>
                <p>
                  for improvements to auction theory and inventions of new
                  auction formats.
                </p>
              </div>
            </div>

            <div className="right-nobel-info-det-bx">
              <h4>The Nobel Prize in Economics 2020</h4>
              <p>
                This prestigious recognition not only validates the importance
                of auctions in the real world but also mirrors the core vision
                of DexAuction.
              </p>
              <p>Our platform is at the forefront of leveraging
                these innovative auction models to redefine the trading and
                valuation of Digital Assets and Tokenised Real World Assets
                (RWAs).</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NobelAunctions;
