import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurBenefits = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const ismobile = window.screen.width <= 768;

  const cards = [
    {
      backgroundImage: "/images/settingsbg.png",
      foregroundImage: "/images/limitless.png",
      alt: "",
      title: "Limitless Customization",
      description:
        "Custom tailored auction solution for different use-cases across multiple blockchains",
    },
    {
      backgroundImage: "/images/nocodebg.png",
      foregroundImage: "/images/code.png",
      alt: "",
      title: "No-code Solution",
      description:
        "Create auction interfaces directly from the dashboard with minimal or no code, easypeasy!",
    },
    {
      backgroundImage: "/images/plugablebg.png",
      foregroundImage: "/images/electricity.png",
      alt: "",
      title: "Pluggable",
      description:
        "Plug-and-play SDK to effortlessly transform your website into an auction hub out of the box",
    },
    {
      backgroundImage: "/images/settingsbg.png",
      foregroundImage: "/images/multiauction.png",
      alt: "",
      title: "Multi-Auction Support",
      description:
        "15+ customisable auction mechanisms supported by the protocol and novel formats being devised",
    },
    {
      
      backgroundImage: "/images/aibg.png",
      foregroundImage: "/images/aisup.png",
      alt: "",
      title: "AI-supercharged",
      description:
        "Autonomous Bidding Agents and Auction Designing Copilot to create better auctions",
    },
  ];

  return (
    <div
      id="features"
      style={{
        background:
          "linear-gradient(180deg, rgba(216, 192, 252, 0.00) 0%, rgba(186, 153, 235, 0.26) 42.71%)",
      }}
    >
      <div className="py-[10%] md:py-[3%] w-[90%] mx-auto">
        {/* top section  */}
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-start gap-y-5 md:gap-y-0">
          <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center">
            OUR BENEFITS
          </button>
          <h1 className="font-medium md:font-regular text-[#110F42] text-4xl md:text-5xl w-[100%] md:w-[66%] py-2">
          Optimal Price Discovery & Trade Mechanics for Digital Assets!
          </h1>
        </div>
        {/* bottom section for desktop  */}
        <div className="hidden md:block">
          <div className="flex flex-col md:flex-row gap-4 flex-wrap py-5 md:py-10">
            <div className="w-[100%] md:w-[32%] h-auto md:h-[350px] mb-5 md:mb-0 pr-0 md:pr-[10%] text-zinc-500 text-lg ">
              <p className="">
                 DexAuction is a suite of Decentralised Auction Infrastructure
               & Tools for conducting different flavours of highly advanced
                and customisable auctions. 
              </p>
              <p className="mt-4">
              All for a simplified Web3 Auction experience! 
              </p>
            </div>

            {cards.map((card, index) => (
              <div
                key={index}
                className="w-[100%] md:w-[32%] h-[350px]  hover:scale-105 transition-transform duration-300 flex flex-col justify-between p-5  bg-cover bg-center rounded-md shadow-md "
                style={{
                  backgroundImage: `url('${card.backgroundImage}')`,
                }}
              >
                <img
                  src={card.foregroundImage}
                  alt=""
                  className="w-[120px] h-[120px] mt-5"
                />
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                    {card.title}
                  </p>
                  <p className="text-base">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* bottom section for mobile  */}
        <div className="block md:hidden">
          <div className="flex flex-col md:flex-row gap-4 flex-wrap py-5 md:py-10">
            <div className="w-[100%] md:w-[32%] h-auto md:h-[350px] mb-5 md:mb-0 pr-0 md:pr-[10%] text-zinc-500 text-lg ">
              <p className="">
                DexAuction is a standardised & permissionless suite of
                Decentralised Auction Infrastructure & Tools for conducting
                different flavours of highly advanced and customisable auctions
                on-chain.
              </p>
              <p className="mt-4">
                Making Web3 Auction experience simplified, accessible and
                exhaustive!
              </p>
            </div>
          </div>
          <Carousel
            swipeable={false}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            keyBoardControl={true}
            className="pb-[15%]"
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className="w-full h-[350px] flex flex-col  justify-between p-5 bg-cover bg-center rounded-md shadow-md overflow-hidden"
                style={{
                  backgroundImage: `url('${card.backgroundImage}')`,
                }}
              >
                <img
                  src={card.foregroundImage}
                  alt=""
                  className="w-[120px] h-[120px] mt-5"
                />
                <div className="mt-14 mb-5">
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                    {card.title}
                  </p>
                  <p className="text-base">{card.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default OurBenefits;
