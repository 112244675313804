import React, { useState } from "react";
import { motion } from "framer-motion";

const AnimatedImage = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const imageVariants1 = {
    initial: {
      opacity: 1,
      scale: 1,
      top: "10px",
      right: "10px",
    },
    hovered: {
      opacity: 1,
      scale: 1,
      top: "-10px",
      right: "-10px",
    },
  };
  const imageVariants2 = {
    initial: {
      opacity: 1,
      scale: 1,
      top: "-20px",
      left: "-50px",
    },
    hovered: {
      opacity: 1,
      scale: 1,
      top: "-40px",
      left: "-60px",
    },
  };
  const imageVariants3 = {
    initial: {
      opacity: 1,
      scale: 1,
      top: "200px",
      left: "0px",
    },
    hovered: {
      opacity: 1,
      scale: 1,
      top: "220px",
      left: "-20px",
    },
  };
  const imageVariants4 = {
    initial: {
      opacity: 1,
      scale: 1,
      top: "110px",
      left: "100px",
      rotate: 45,
    },
    hovered: {
      opacity: 1,
      scale: 1,
      top: "130px",
      left: "120px",
      rotate: 45,
    },
  };

  return (
    <div>
      <div
        className="rounded-[25px] token-bg-bx"
        style={{
          position: "relative",
          width: "280px",
          height: "280px",
          backgroundColor: "white",
          overflow: "visible",
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <motion.img
            src="/images/anim1.png"
            alt="Image 1"
            style={{
              width: "60px",
              height: "60px",
              position: "absolute",
              ...imageVariants1[isHovered ? "hovered" : "initial"],
            }}
            initial="initial"
            animate={isHovered ? "hovered" : "initial"}
            variants={imageVariants1}
            transition={{ duration: 0.5 }}
          />
          <motion.img
            src="/images/anim2.png"
            alt="Image 2"
            style={{
              width: "200px",
              height: "200px",
              position: "absolute",
              ...imageVariants2[isHovered ? "hovered" : "initial"],
            }}
            initial="initial"
            animate={isHovered ? "hovered" : "initial"}
            variants={imageVariants2}
            transition={{ duration: 0.5 }}
          />
          <motion.img
            src="/images/anim3.png"
            alt="Image 3"
            style={{
              width: "60px",
              height: "60px",
              position: "absolute",
              ...imageVariants3[isHovered ? "hovered" : "initial"],
              top: 0,
              left: 0,
            }}
            initial="initial"
            animate={isHovered ? "hovered" : "initial"}
            variants={imageVariants3}
            transition={{ duration: 0.5 }}
          />
          <motion.img
            src="/images/anim2.png"
            alt="Image 4"
            style={{
              width: "180px",
              height: "180px",
              position: "absolute",
              ...imageVariants4[isHovered ? "hovered" : "initial"],
              top: "220px",
              left: "100px",
            }}
            initial="initial"
            animate={isHovered ? "hovered" : "initial"}
            variants={imageVariants4}
            transition={{ duration: 0.5 }}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default AnimatedImage;
