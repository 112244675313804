export const DexaDataOne = [

    {
      text : 'Solution for   Primary Issuance/ Private Offering of Tokenised RWA',
      button : 'IRO LaunchPad',
    },
    {
      text : 'Solution for  Secondary Market/ Public Trading of Tokenised RWA',
      button : 'RDEX',
    },
    {
        text : 'Solution for Public Trading in DePIN Marketplaces',
        button : 'DePIN Matcher',
      },
      {
        text : 'Solutions for Non-RWA & Non-DePIN Sector Use-cases',
        button : 'Other Products',
      },
    
    
  ]



 
