import React from 'react'

const Getyourworth = () => {
  return (
    <>
        <section className="dexa-token-comp get-your-worth-comp" id="vision">
        <div className="common-container">
          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex">
            <h3>Enabling Fair Digital Asset Trading</h3>

            <p>
              We are committed to being the <b>industry standard</b> for trading and
              pricing of Digital Assets & for conducting auctions on-chain.
            </p>
          </div>



          <div className="text-img-bx-main">
            
            <div className="text-img-bx text-img-bx1">
              <img src="/images/group_2.png" alt="" />
            </div>

            <div className="text-img-bx text-img-bx2">
              <img src="/images/group_3.png" alt="" />
            </div>

            <div className="text-img-bx text-img-bx3">
              <img src="/images/group_4.png" alt="" />
            </div>

            <div className=" mob-img-bx">
              <img src="/images/Get_your_worth.png" alt="" />
            </div>
           
          </div>



          <p className="last-center-text">
          DexAuction is a pricing, valuation, trading and distribution 
          solution for Digital Assets and Tokenised RWAs powered by 
          decentralised auctions and oracle price feeds.
           
          </p>
        </div>
      </section>
    </>
  )
}

export default Getyourworth
