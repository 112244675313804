import React from "react";

const Deepmatcher = () => {
  return (
    <>
      <section className="iro-comp" id="deepmatcher">
        <div className="common-container">
          <div className="mb-5 iro-round-text">
            <button
              class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
              id="research"
            >
              DEPIN MATCHER
            </button>
          </div>

          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex iro-content-bx reimag-content-bx">
            <h3>Deep in DePIN</h3>

            <p>
              The DePIN Matcher is inspired by traditional “Negotiauctions”
              which combine the competitive bidding of an auction with the
              one-on-one dealmaking of a negotiation.
            </p>
          </div>

          <div className="iro-grid-bx iro-grid-bx-main">
            <div className="left-iro-img left-deep-img">
              <img src="/productimages/cube.png" alt="" />
            </div>

            <div className="right-iro-content-flex-bx right-deep-content-flex-bx">
              <div className="full-width-iro-content-bx">
                <p>
                Our DePIN Matcher solution can be integrated into your applications to allow your users and traders to access the physical and digital infrastructure of your network. 

                </p>
                <p className="mt-5">
                Use our DePIN Matcher SDK to supercharge your DePIN Marketplace with efficient trading and enable 
Perfect order matching
Optimal Price Discovery
                </p>
              </div>

              <div className="iro-grid-bx iro-grid-bx2">
                <div className="iro-mini-bx">
                  <div className="iro-mini-img">
                    <img src="/productimages/iro-puz-img-1.png" alt="" />
                  </div>
                  <h5>
                    Optimal Price <br /> Discovery
                  </h5>
                </div>

                <div className="iro-mini-bx iro-mini-bx2">
                  <div className="iro-mini-img iro-mini-img2">
                    <img src="/productimages/match-img.png" alt="" />
                  </div>
                  <h5>
                  Perfect order   <br /> matching
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Deepmatcher;
