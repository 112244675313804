export  const DexaDataTwo = [


    {
        iconOne : '/productimages/dexa-icon-1.png',
        iconTwo : '/productimages/dexa-icon-2.png',
        iconThree : '/productimages/dexa-icon-3.png',
        iconFour : '/productimages/dexa-icon-4.png',
        textOne : 'Blind Batch Auction',
        textTwo : 'Dutch  Auction',
        textThree : 'Sealed Bid Auction',
        textFour : 'Fixed Price Lisitng',


    },

    {
        iconOne : '/productimages/dexa-icon-5.png',
        iconTwo : '/productimages/dexa-icon-6.png',
        iconThree : '/productimages/dexa-icon-7.png',
        iconFour : '/productimages/dexa-icon-8.png',
        textOne : 'Dutch  Auction',
        textTwo : 'Limit Order Books',
        textThree : 'AMM Swaps',
        textFour : 'Bonding Curves',


    },


    {
        iconOne : '/productimages/dexa-icon-9.png',
        iconTwo : '/productimages/dexa-icon-10.png',
        iconThree : '/productimages/dexa-icon-11.png',
        iconFour : '/productimages/dexa-icon-12.png',
        textOne : 'Bi-direction Auction',
        textTwo : 'English  Auction',
        textThree : 'Sealed Bid  Auction',
        textFour : 'Batch Auction',


    },


    {
        iconOne : '/productimages/dexa-icon-10.png',
        iconTwo : '/productimages/dexa-icon-13.png',
        iconThree : '/productimages/dexa-icon-14.png',
        iconFour : '/productimages/dexa-icon-15.png',
        textOne : 'English Auction',
        textTwo : 'Reverse Auction',
        textThree : 'Penny Auction',
        textFour : 'And more...',


    },
        
    
  ]