import React from "react";

const ReimagningComp = () => {
  return (
    <>
      <section className="reimagning-comp" id="reimagning">
        <div className="common-container">
          <div className="global-market-size-info-flex-bx contact-text-flex-bx get-yr-wrth-flex reimag-content-bx">
            <h3>
              Reimagining <br /> Pricing & Trading of Tokenised Assets
            </h3>

            <p>
              Move beyond unsuitably designed “traditional” exchange mechanism
              like Limit Order Books, Automated Market Makers and the boring
              Fixed Price Sales for your Tokenised Real World Assets!
            </p>
          </div>

          <div className="reimag-img-main-bx">
            <div className="reimag-img-bx">
              <img src="/productimages/hero-img.png" alt="" />
            </div>
          </div>

          <p className="reimag-para-cent">
            We leverage our decentralised auction powered LaunchPad and
            Alternative Trading Solution (ATS) to achieve optimal pricing,
            valuation, trading and distribution for Tokenised RWAs.
          </p>
        </div>
      </section>
    </>
  );
};

export default ReimagningComp;
