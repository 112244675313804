import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import AnimatedImage from "../../components/AnimatedImage/AnimatedImage";

const Token = () => {
  const cardsData = [
    {
      backgroundImage: "/images/utilitybg1.png",
      image: "/images/utility1.png",
      title: "Premium & Priority Access",
      description:
        "Early and exclusive access to premium features within the protocol and to high-demand auctions within the ecosystem",
    },
    {
      backgroundImage: "/images/utilitybg2.png",
      image: "/images/utility2.png",
      title: "Collateralize bids",
      description:
        "Participants will collateralize their bids with DEXA tokens to prevent frivolous or spam bids",
    },
    {
      backgroundImage: "/images/utilitybg3.png",
      image: "/images/utility3.png",
      title: "Governance",
      description:
        "Participate in governance decisions, proposing and voting on protocol upgrades, auction integrations, parameter adjustments, fee changes",
    },
    {
      backgroundImage: "/images/utilitybg4.png",
      image: "/images/utility4.png",
      title: "Payments",
      description:
        "Pay transaction fees within the DexAuction ecosystem, such as trading fees, auction creation fees, or withdrawal fees",
    },
    {
      backgroundImage: "/images/utilitybg5.png",
      image: "/images/utility5.png",
      title: "Buyback & Burn Auction",
      description:
        "Bi-weekly 50% of the collected fees on the protocol will undergo a buy back and burn process via a community-led auction",
    },
    {
      backgroundImage: "/images/utilitybg6.png",
      image: "/images/utility6.png",
      title: "AI tooling",
      description:
        "Access to DEXA Autonomous Bidding Agents and Auction Designing Copilot and to other AI features in DexAuction",
    },
  ];

  const location = useLocation()
  const activeHash = location?.hash

  useEffect(() => {
    const targetDiv = document.getElementById(activeHash?.substring(1));

    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  },[activeHash])

  return (
    <div
      id="vision1"
      className={`text-white bg-cover`}
      style={{
        backgroundImage: `${"url('/images/dexabg.png')"}`,
      }}
    >
      <div className="relative  z-0" >
        <img
          src="/images/dexamobile.png"
          alt=""
          className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1 w-[342px] h-[102px] md:w-[80%] md:h-[380px] mt-5"
        />
      </div>
      <div className="w-[90%] mx-auto  py-[20%] md:py-[5%] " id="token">
        <div className="z-20 relative" >
          <button className="text-white text-xl font-bold border border-white py-2 px-4 rounded-full inline-flex items-center ">
            TOKEN
          </button>
        </div>

        <div className=" token-check-bg flex flex-col md:flex-row justify-between items-center mt-[10%] mb-[5%] md:mb-0 md:mt-[25%]">
          <div className="flex flex-col gap-5 md:gap-20 order-2	md:order-1 w-full md:w-auto mt-16 md:mt-0">
            <div className="">
              <p className="text-[#EFEFE5E5] text-xl mb-5">TOTAL SUPPLY</p>
              <p className="text-4xl md:text-5xl font-regular">200 Million</p>
            </div>
            <div className="">
              <p className="text-[#EFEFE5E5]  text-xl mb-5">
                TOKEN TYPE
              </p>
              <p className="text-4xl md:text-5xl font-regular text-sml">Hybrid  <br/> <span>(Utility & Governance) </span> </p>
            </div>
          </div>
          <div className="scale-75 md:scale-100 order-1	md:order-2 w-[80%] md:w-auto justify-center mt-10 md:mt-0">
            <AnimatedImage /> 
          </div>
          <div className="flex flex-col gap-5 md:gap-20 order-3 w-full md:w-auto mt-5 md:mt-0">
            <div className="">
              <p className="text-[#EFEFE5E5] text-xl mb-5">TOKEN STANDARD</p>
              <p className="text-4xl md:text-5xl font-regular">ERC-20</p>
            </div>
            <div className="">
              <p className="text-[#EFEFE5E5] text-xl mb-5">SUPPLY CONTROL MODEL</p>
              <p className="text-4xl md:text-5xl font-regular text-sml">Deflationary  <br/> <span> (By Burning) </span></p>
            </div>
          </div>
        </div>

        <div id="utility">
          <h1 className="text-4xl md:text-5xl font-medium text-center my-5 md:my-10 pt-10 md:pt-20 pb-10 md:pb-0 ">
            Token Utility
          </h1>
          <div className="flex flex-col md:flex-row justify-between items-center gap-y-5 flex-wrap">
            {cardsData.map((card, index) => (
              <div
                className="w-[100%] md:w-[32%] h-[330px] md:h-[400px] px-5 py-8 bg-cover bg-center flex flex-col justify-between rounded-lg"
                style={{
                  backgroundImage: `url('${card?.backgroundImage}')`,
                }}
              >
                <img src={card?.image} alt="" className="w-[100px]" />
                <div>
                  <p className="font-semibold text-2xl mb-3">{card?.title}</p>
                  <p className="text-base font-normal">{card?.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Token;
