import React, { useEffect } from "react";

const Rnd = () => {

  // useEffect(() => {
  //   const scrollToAboutFourComponent = () => {
  //     const roadCmponent = document.getElementById('roadmap');
  //     if (roadCmponent) {
  //       roadCmponent.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   };

  //   scrollToAboutFourComponent();
  // }, []);
  
  return (
    <div className="bg-[#5300CC] text-white py-[15%] md:py-[5%] " id="rnd">
      <div className="w-[90%] mx-auto">
        <div className="mb-5">
          <button
            class="text-white text-lg font-bold border border-white py-2 px-4 rounded-full inline-flex items-center "
            id="research"
          >
            R&D
          </button>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-[100%] md:w-[40%]">
            <p className=" text-4xl md:text-6xl font-medium my-10 md:my-0">
              Discover DexAuction Research Lab
            </p>
          </div>
          <div className="w-[100%] md:w-[50%] space-bottom">
            <p className=" text-white text-opacity-90 text-lg mb-10 md:mb-0">
              Welcome to DexAuction Research Lab, a pioneering in-house think
              tank where brilliance converges, and innovation thrives. We unite
              the intellectual prowess of esteemed universities, renowned
              economists, accomplished researchers, and industry luminaries,
              embarking on a collective journey towards unparalleled excellence.
            </p>
          </div>
        </div>

        <div
          className="flex flex-col md:flex-row justify-between items-center py-[5%] main-parent-flex"
          style={{
            backgroundImage: `url('/images/rndmainbg.png')`,
          }}
        >
          <div className="flex flex-col gap-5 md:gap-20 order-2	md:order-1 w-full md:w-auto">
            <div className="space-top">
              <p className="text-stone-200 text-opacity-90 text-xl font-normal mb-3">
                University Collaborations
              </p>
              <p className="text-4xl md:text-6xl font-bold">5+</p>
            </div>
            <div className="">
              <p className="text-stone-200 text-opacity-90 text-xl font-normal mb-3">
                Novel Auction Strategies invented
              </p>
              <p className="text-4xl md:text-6xl font-bold">3</p>
            </div>
          </div>
          <div className="order-1	md:order-2 puzle-img-flex">
            <div className="puzle-img-bx-abs">
           <div className="puzl-img puzl-img1">
            <img src="/images/box-4.png" alt="" />
           </div>
           <div className="puzl-img puzl-img2">
            <img src="/images/box-1.png" alt="" />
           </div>
           <div className="puzl-img puzl-img3">
            <img src="/images/box-3.png" alt="" />
           </div>
           <div className="puzl-img puzl-img4">
            <img src="/images/box-2.png" alt="" />
           </div>
           </div>
          </div>
          <div className="flex flex-col gap-5 md:gap-20 order-3 mt-5 md:mt-0 w-full md:w-auto">
            <div className="">
              <p className="text-stone-200 text-opacity-90 text-xl font-normal mb-3">
                Industry Partnerships
              </p>
              <p className="text-4xl md:text-6xl font-bold">10+</p>
            </div>
            <div className="">
              <p className="text-stone-200 text-opacity-90 text-xl font-normal mb-3">
                Research Papers published
              </p>
              <p className="text-4xl md:text-6xl font-bold">2</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between py-10">
          <div className="w-[100%] md:w-[25%]">
            <p className=" text-3xl font-medium  leading-10 mb-5">Our Focus</p>
            <p className=" text-[#FFFFFFE5] text-lg font-normal mb-3 ">
              “These pursuits are empowered by the forefront of innovation, with
              Distributed Ledger Technologies, Blockchains, Artificial
              Intelligence and Machine Learning as our guiding lights.”
            </p>
            <p className="my-10 md:my-0">
              <span className=" text-xl font-medium ">- </span>
              <span className=" text-xl font-bold ">Mudit Marda</span>
              <span className=" text-xl font-medium ">, F</span>
              <span className=" text-xl font-normal ">ounder and CEO</span>
            </p>
          </div>

          <div className="w-[100%] md:w-[65%]  mt-10 md:mt-0">
            <div class=" grid grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="md:col-span-2 bg-cover bg-center p-8 rounded-lg  "
                style={{
                  backgroundImage: `url('/images/rndbg1.png')`,
                }}
              >
                <p className="text-xl font-semibold mb-5">
                  Economic and Game Theory
                </p>
                <p className="text-base font-normal">
                  Navigating strategic interactions and equilibrium concepts.
                </p>
              </div>
              <div
                className=" p-8 bg-cover bg-center rounded-lg "
                style={{
                  backgroundImage: `url('/images/rndbg2.png')`,
                }}
              >
                <p className="text-xl font-semibold mb-5">Auction Theory</p>
                <p className="text-base font-normal">
                  Deciphering the intricacies of bidding strategies and auction
                  dynamics.
                </p>
              </div>
              <div
                className="  bg-cover bg-center p-8 rounded-lg"
                style={{
                  backgroundImage: `url('/images/rndbg2.png')`,
                }}
              >
                <p className="text-xl font-semibold mb-5">Market Design</p>
                <p className="text-base font-normal">
                  Crafting efficient, equitable, and resilient auction-based
                  marketplaces.
                </p>
              </div>
              <div
                className=" p-8 bg-cover bg-center rounded-lg "
                style={{
                  backgroundImage: `url('/images/rndbg2.png')`,
                }}
              >
                <p className="text-xl font-semibold mb-5">Cryptoeconomics</p>
                <p className="text-base font-normal">
                  Exploring the intersection of economics and blockchain
                  technology.
                </p>
              </div>
              <div
                className=" p-8 bg-cover bg-center  rounded-lg"
                style={{
                  backgroundImage: `url('/images/rndbg2.png')`,
                }}
              >
                <p className="text-xl font-semibold mb-5">
                  Behavioral Economics
                </p>
                <p className="text-base font-normal">
                  Unraveling human psychology's influence on economic decisions
                  involved in auctions.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* be a part portion  */}
{/* 
        <div className=" pt-0 pb-0 md:pt-[5%] md:pb-0">
          <div
            className="  flex flex-col justify-center  gap-10 py-[5%]  bg-cover bg-center"
            style={{
              backgroundImage: `url('/images/rndmainbg.png')`,
            }}
          >
            <div className=" text-center text-4xl md:text-6xl font-bold  ">
              Be a Part of Our Journey
            </div>
            <div className="w-[90%] md:w-[60%] mx-auto text-center text-lg md:text-2xl font-normal text-white text-opacity-90   ">
              Join us in reshaping the future of economic understanding, market
              dynamics, and technological integration. Together, we forge paths
              of discovery, collaboration, and transformation.
            </div>

            <div className="flex justify-center">
              <button
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/1Is8klzf3vWAD31vrQct7_qOGlFNntkhdJ6OcS5mENoA"
                  )
                }
                className="inline-flex items-center  px-5 py-2 mb-3  text-lg font-bold text-[#110F42] no-underline align-middle bg-white border border-transparent border-solid rounded-lg cursor-pointer select-none sm:mb-0 sm:w-auto 
          "
              >
                Join Us
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Rnd;
