import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Usecase = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="w-[90%] mx-auto py-[10%] md:py-[5%]" id='usecase'>
      <div>
        <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center">
         AUCTIONING USE CASES
        </button>
      </div>

      {/* bottom section  */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-[10px]  pt-[5%]">
        {/* First Column */}
        <div className="col-span-1 flex flex-col h-auto md:h-screen text-[#110F42]">
          <div className="w-[100%] md:w-[70%] ">
            <h1 className=" text-indigo-950  font-medium  leading-10 text-4xl md:text-6xl ">
              Industry Wide Use-Cases
            </h1>

            <p className="py-5 text-[#80848D]">
              Becoming the standard & de facto choice for conducting auctions
              for different use-cases on-chain.
            </p>
            <p className="text-[#80848D]">
              Think auctions with blockchain, think DexAuction!
            </p>
          </div>
        </div>

        {/* Second Column */}
        <div className="col-span-2 h-auto md:h-screen md:overflow-scroll usecase mt-10 md:mt-0 desktop-view">
          <div className="bg-[#000] rounded-lg h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Tokenized Assets</h4>
                  <p className="w-[70%]">
                    Real World Assets (RWA)​ and Virtual/Digital Assets
                    tokenised into auctionable on-chain entities
                  </p>
                </div>
              </div>
              <div className="relative flex justify-center">
                <img
                  src="/images/usecase2.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#5757AC] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Real World Applications</h4>
                  <p className="w-[70%]">
                    Energy Trading, Telecom Spectrums, Advertisement Slots,
                    Government Procurement, Broadcasting Licenses
                  </p>
                </div>
              </div>
              <div className="relative flex items-center">
                <img src="/images/usecase6.png" alt="" className="h-auto " />
              </div>
            </div>
          </div>
          <div className="bg-[#0E2000] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Crypto DEX</h4>
                  <p className="w-[70%]">
                    Fungible Token Crypto DEX running on Double Auctions,
                    Periodic Batch Auctions, Dutch Auctions, etc.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase3.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#120128] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">NFT Marketplace</h4>
                  <p className="w-[70%]">
                    NFT Auction house, Storefront, Marketplace and a No-code
                    Whitelabel NFT solution
                  </p>
                </div>
              </div>
              <div className="relative flex justify-center">
                <img
                  src="/images/laptop.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>

          <div className="bg-[#EFEFEF] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-black">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3 text-indigo-950 text-xl font-bold">
                    Service Allocation
                  </h4>
                  <p className="w-[70%]">
                    Fungible Token Crypto DEX running on Double Auctions,
                    Periodic Batch Auctions, Dutch Auctions, etc.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase4.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#000832] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Blockchain Protocols</h4>
                  <p className="w-[70%]">
                    Incentive and Consensus Protocols involving Validators,
                    Miners, etc., Transaction Fee (Gas) Mechanisms, Token Sale &
                    Exchange
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase5.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mob-view">
        <Carousel
            swipeable={false}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            keyBoardControl={true}
            className="pb-[15%]"
          >
        <div className="bg-[#000] rounded-lg h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Tokenized Assets</h4>
                  <p className="w-[70%]">
                    Real World Assets (RWA)​ and Virtual/Digital Assets
                    tokenised into auctionable on-chain entities
                  </p>
                </div>
              </div>
              <div className="relative flex justify-center">
                <img
                  src="/images/usecase2.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#5757AC] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Real World Applications</h4>
                  <p className="w-[70%]">
                    Energy Trading, Telecom Spectrums, Advertisement Slots,
                    Government Procurement, Broadcasting Licenses
                  </p>
                </div>
              </div>
              <div className="relative flex items-center">
                <img src="/images/usecase6.png" alt="" className="h-auto " />
              </div>
            </div>
          </div>
          <div className="bg-[#0E2000] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Crypto DEX</h4>
                  <p className="w-[70%]">
                    Fungible Token Crypto DEX running on Double Auctions,
                    Periodic Batch Auctions, Dutch Auctions, etc.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase3.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#120128] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">NFT Marketplace</h4>
                  <p className="w-[70%]">
                    NFT Auction house, Storefront, Marketplace and a No-code
                    Whitelabel NFT solution
                  </p>
                </div>
              </div>
              <div className="relative flex justify-center">
                <img
                  src="/images/laptop.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>

          <div className="bg-[#EFEFEF] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-black">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3 text-indigo-950 text-xl font-bold">
                    Service Allocation
                  </h4>
                  <p className="w-[70%]">
                    Fungible Token Crypto DEX running on Double Auctions,
                    Periodic Batch Auctions, Dutch Auctions, etc.
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase4.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          <div className="bg-[#000832] rounded-lg  h-auto overflow-hidden md:h-[316px] relative mb-3 text-white">
            <div className="flex flex-col md:flex-row justify-between h-full">
              <div className="flex-grow flex-basis-0">
                <div className="flex flex-col h-full justify-between py-10 px-5">
                  <h4 className="mb-3">Blockchain Protocols</h4>
                  <p className="w-[70%]">
                    Incentive and Consensus Protocols involving Validators,
                    Miners, etc., Transaction Fee (Gas) Mechanisms, Token Sale &
                    Exchange
                  </p>
                </div>
              </div>
              <div className="relative">
                <img
                  src="/images/usecase5.png"
                  alt=""
                  className="h-auto md:h-full"
                />
              </div>
            </div>
          </div>
          </Carousel>
        </div>
        
      </div>
    </div>
  );
};

export default Usecase;
