import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DefiAuction = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const cardsData = [
    {
      image: "/images/defi1.png",
      background: "/images/defigb1.png",
      title: "Privacy and ownership",
      description:
        "Allow users to retain full control and ownership of their data and assets and to participate in auctions without disclosing sensitive information.",
    },
    {
      image: "/images/defi2.png",
      background: "/images/defigb2.png",
      title: "Reduce counterparty risks",
      description:
        "Operate without reliance on centralized intermediaries or authority figures like an auction house.",
    },
    {
      image: "/images/defi3.png",
      background: "/images/defigb3.png",
      title: "High customisability, flexibility",
      description:
        "Integrable in various DeFi protocols, NFT dApps, Web3 applications, and Web2 platforms, promoting composability.",
    },
    {
      image: "/images/defi4.png",
      background: "/images/defigb4.png",
      title: "Trust and Transparency",
      description:
        "Give participants visibility into auction processes, bids, asset attributes and ownership, and outcomes.",
    },
    {
      image: "/images/defi5.png",
      background: "/images/defigb5.png",
      title: "Cost Efficiency",
      description:
        "Levy lower fees and lesser commissions compared to traditional auction houses and platforms.",
    },
    {
      image: "/images/defi6.png",
      background: "/images/defigb6.png",
      title: "Speed and efficiency",
      description:
        "Provide seamless on-chain settlements and reduce the time and complexity associated with traditional auctions.",
    },
    {
      image: "/images/defi7.png",
      background: "/images/defigb7.png",
      title: "Security",
      description:
        "Minimise the risk of fraud or manipulation using trustless smart contract execution.",
    },
    {
      image: "/images/defi8.png",
      background: "/images/defigb8.png",
      title: "Accessibility and inclusivity",
      description:
        "Globally accessible to anyone with an internet connection and a digital wallet, fostering inclusivity and enabling participation worldwide.",
    },
  ];
  return (
    <div className="bg-[#EFEFEF] pb-[20%] md:pb-[5%]" id="defi">
      <div className="w-[90%] mx-auto py-[10%] md:py-[3%]">
      <div>
        <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center">
         DEFI AUCTION BENEFITS
        </button>
      </div>
        <div>
          <h1 className="font-medium text-[#110F42] text-4xl md:text-5xl w-[100%] md:w-[45%] pt-20 md:pt-10 ">
            The DeFi Auction Advantage
          </h1>
        </div>

        <div className="hidden md:block">
          <div className="flex flex-col md:flex-row gap-4 flex-wrap py-5 md:py-10">
            <div className="w-[100%] md:w-[32%] h-auto md:h-[330px] mb-5 md:mb-0 text-zinc-500 text-lg">
              <p className="pr-0 md:pr-[30%] my-5">
                DexAuction brings the future of Asset Exchange, 
                Price Formation and Resource Allocation.
              </p>
              <p className="pr-0 md:pr-[30%] ">
                Think beyond Fixed Price Listings, Automated Market Makers,
                Orderbooks, DeFi & CeFi exchanges, Traditional Financial Markets
                and Primitive Auctions, think DexAuction!
              </p>
            </div>
            {cardsData.map((card, index) => (
              <div
                key={index}
                className="w-[100%] md:w-[32%] h-[330px] flex flex-col justify-between p-5 bg-cover bg-center rounded-md shadow-md hover:scale-105 transition-transform duration-300"
                style={{
                  backgroundImage: `url('${card.background}')`,
                }}
              >
                <img src={card.image} alt="" className="w-[120px] h-[120px]" />
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                    {card.title}
                  </p>
                  <p className="text-base text-[#6A6D77]">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="block md:hidden">
          <div className="flex flex-col md:flex-row gap-4 flex-wrap py-5 md:py-10">
            <div className="w-[100%] md:w-[32%] h-auto md:h-[330px] mb-5 md:mb-0 text-zinc-500 text-lg">
              <p className="pr-0 md:pr-[30%] mb-5">
                DexAuction brings the future of Asset Exchange and Trading
                through its specialised Decentralised Auction Protocol.
              </p>
              <p className="pr-0 md:pr-[30%] ">
                Think beyond Fixed Price Listings, Automated Market Makers,
                Central Limit Orderbooks, and Primitive Auctions, think
                DexAuction!
              </p>
            </div>
          </div>
          <Carousel
            swipeable={false}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            keyBoardControl={true}
            className="pb-[15%]"
          >
            {cardsData.map((card, index) => (
              <div
                key={index}
                className="w-[100%] md:w-[32%] h-[330px] flex flex-col justify-between p-5 bg-cover bg-center rounded-md shadow-md hover:scale-105 transition-transform duration-300"
                style={{
                  backgroundImage: `url('${card.background}')`,
                }}
              >
                <img src={card.image} alt="" className="w-[120px] h-[120px]" />
                <div>
                  <p className="font-bold text-xl text-[#110F42] mb-2">
                    {card.title}
                  </p>
                  <p className="text-base text-[#6A6D77]">{card.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default DefiAuction;
