import React from "react";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EastIcon from "@mui/icons-material/East";
import {
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import dexvideo from "./Dexauctionvideo.json";
import Lottie from "lottie-react";

const Banner = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const carouselRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const rightarrow = <div></div>;

  return (
    <>
      {/* for mobile device  */}
      <div className="mob-bg-vew block md:hidden h-auto">
        <div className="w-[90%] mx-auto mt-[100px]  ">
          <h1 className="text-indigo-950  font-extrabold text-4xl md:text-6xl font-['Eudoxus Sans']  ">
            <p className="mb-4">Get Your Worth</p>
            {/* <p> Auction Protocol</p> */}
          </h1>
          <p className="text-indigo-950 text-xl font-normal  my-8">
          Optimally Price & Trade Tokenised RWAs and Digital Assets, leveraging our {" "}
            <br />
            Decentralised Auction Infrastructure & Tools. 
            {/* Elevating DeFi & NFT Trading to the next level, leveraging
            AI-powered Decentralised Auction Infrastructure & Tools. */}
          </p>
          <button
            onClick={() => window.open("https://docs.dex.auction")}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="inline-flex items-center justify-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold text-white text-center no-underline align-middle bg-[#5300CC] hover:bg-[#3A008F] border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto "
          >
            <p className="mr-3"> Start Building</p>
            {/* arrow slider  */}
            <div className={`arrow-slider  ${"w-[30px] "}`}>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                transitionDuration={500}
                ref={carouselRef}
                customLeftArrow={rightarrow}
                customRightArrow={rightarrow}
              >
                <div className={`  flex justify-center items-center`}>
                  <EastIcon style={{ fontSize: `${"1.5rem"}` }} />
                </div>
                <div className={`  flex justify-center items-center`}>
                  <EastIcon style={{ fontSize: `${"1.5rem"}` }} />
                </div>
              </Carousel>
            </div>
          </button>
          <button
            onClick={() => scrollToElement("ourproduct")}
            className="inline-flex items-center justify-center w-full px-5 py-3 ml-0 md:ml-5 mb-3 mr-1 text-base font-bold text-black hover:text-white no-underline align-middle bg-white hover:bg-[#581C87] border border-[#D9D9D9] border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto "
          >
            Explore Products
          </button>

          <h5 className="text-indigo-950 text-sm font-normal mt-10 mb-5">
            WINNER OF
          </h5>
          <div className="flex flex-row items-center gap-5 md:gap-10 flex-wrap ">
            <Link to="#">
              <img
                src="/images/coinlist.png"
                alt=""
                className="hover:scale-110 transition-transform duration-300 w-[66px] h-[52px] md:w-[110px] md:h-[87px]"
              />
            </Link>
            <Link to="#">
              <img
                src="/images/Clhackathon.png"
                alt=""
                className="hover:scale-110 transition-transform duration-300 w-[52px] h-[52px] md:w-[72px] md:h-[72px]"
              />
            </Link>
            <Link to="#">
              <img
                src="/images/Binancebuild.png"
                alt=""
                className="hover:scale-110 transition-transform duration-300 w-[81px] h-[52px] md:w-[110px] md:h-[72px]"
              />
            </Link>
          </div>
        </div>
      </div>
      {/* for desktop  */}
      <div className="hidden mob-bg-vew md:block min-h-screen md:min-h-auto">
        <div className="relative">
          <Lottie
            animationData={dexvideo}
            backgroundOpacity={0}
            className="w-full h-full"
          />

          <div className="w-[100%] md:w-[55%]   absolute top-[200px] left-[100px] ">
            <h1 className="text-indigo-950  font-extrabold text-4xl md:text-6xl font-['Eudoxus Sans']  ">
              <p className="mb-3">Get Your Worth</p>
            </h1>
            <p className="text-indigo-950 text-xl font-normal  my-8">
            Optimally Price & Trade Tokenised RWAs and Digital Assets, leveraging our {" "}
              <br />
              Decentralised Auction Infrastructure & Tools. 
              {/* Elevating DeFi & NFT Trading to the next level, leveraging
            AI-powered Decentralised Auction Infrastructure & Tools. */}
            </p>
            <button
              onClick={() => window.open("https://docs.dex.auction")}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="inline-flex items-center justify-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold text-white text-center no-underline align-middle bg-[#5300CC] hover:bg-[#3A008F] border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto "
            >
              <p className="mr-3"> Start Building</p>
              {/* arrow slider  */}
              <div className={`arrow-slider  ${"w-[30px] "}`}>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={false}
                  transitionDuration={500}
                  ref={carouselRef}
                  customLeftArrow={rightarrow}
                  customRightArrow={rightarrow}
                >
                  <div className={`  flex justify-center items-center`}>
                    <EastIcon style={{ fontSize: `${"1.5rem"}` }} />
                  </div>
                  <div className={`  flex justify-center items-center`}>
                    <EastIcon style={{ fontSize: `${"1.5rem"}` }} />
                  </div>
                </Carousel>
              </div>
            </button>
            <button
              onClick={() => scrollToElement("ourproduct")}
              className="inline-flex items-center justify-center w-full px-5 py-3 ml-0 md:ml-5 mb-3 mr-1 text-base font-bold text-black hover:text-white no-underline align-middle bg-white hover:bg-[#581C87] border border-[#D9D9D9] border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto "
            >
              Explore Products
            </button>

            <h5 className="text-indigo-950 text-sm font-normal mt-10 mb-5">
              WINNER OF
            </h5>
            <div className="flex flex-row items-center gap-5 md:gap-10 flex-wrap ">
              <Link to="#">
                <img
                  src="/images/coinlist.png"
                  alt=""
                  className="hover:scale-110 transition-transform duration-300 w-[66px] h-[52px] md:w-[110px] md:h-[87px]"
                />
              </Link>
              <Link to="#">
                <img
                  src="/images/Clhackathon.png"
                  alt=""
                  className="hover:scale-110 transition-transform duration-300 w-[52px] h-[52px] md:w-[72px] md:h-[72px]"
                />
              </Link>
              <Link to="#">
                <img
                  src="/images/Binancebuild.png"
                  alt=""
                  className="hover:scale-110 transition-transform duration-300 w-[81px] h-[52px] md:w-[110px] md:h-[72px]"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
