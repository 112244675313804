import React from "react";

const Getstarted = () => {
  return (
    <div className="bg-white py-[5%]">
      <div
        className=" bg-cover bg-center flex flex-col justify-center  gap-5 py-[5%] "
        style={{
          backgroundImage: `url('/images/dexbg.png')`,
        }}
      >
        <div className=" text-center  text-4xl md:text-6xl font-bold  leading-10">
          Get started with DexAuction
        </div>
        <div className="w-[90%] md:w-[60%] mx-auto text-center  text-lg md:text-2xl font-medium  ">
          Build Auction powered Web3 DApps with our suite of Decentralised
          Auction Infrastructure & Tools
        </div>

        <div className="flex justify-center">
          <button
            className="inline-flex items-center  px-5 py-3 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-[#5300CC] hover:bg-blue-500 border border-transparent border-solid rounded-lg cursor-pointer select-none sm:mb-0 sm:w-auto 
          "
          >
            Start Building
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Getstarted;
