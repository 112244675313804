import React, {  useState, } from "react";
import {
  Menu,
} from "@mui/icons-material";
import "react-dropdown/style.css";

import { Link, NavLink, useNavigate, } from "react-router-dom";


const Navbar = () => {
  const [isExpanded, setExpanded] = useState(false);
  const [linkActive, setLinkActive] = useState("");
  const [drpdwnLi, setDrpdwnLi] = useState("");
  const [mobileNav , setMobileNav] = useState(false);

  const navigate = useNavigate();

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`${
          isExpanded ? "h-[500px]" : "h-[60px]"
        } fixed z-30 md:h-[70px] w-screen  bg-white  shadow-xl text-black   py-2 transition-all ease-out duration-300  md:flex md:items-center z-50 main-nav`}
      >
        <div className="flex flex-col md:flex-row justify-between w-[90%] mx-auto nav">
          <div className="flex justify-between gap-3 items-center ">
            <Link to="/" className="font-extrabold text-2xl no-underline">
              <img
                src="/images/dexlogo.png"
                alt={"Fundmagnet"}
                className={"w-[150px] "}
              />
            </Link>

            <button onClick={() => setMobileNav(!mobileNav)} className="visible md:invisible">
              <Menu fontSize="large" className="" />
            </button>
          </div>
          {window.location.pathname !== "/contact-us" && (
            <div className={mobileNav ? "flex gap-16 navbar-links-flex navlinkmobileactive" : "flex gap-16 navbar-links-flex"}>
              <div
                className={`flex flex-col md:flex-row md:items-center gap-3 mt-[20%] md:mt-0 md:gap-5 transition-all ease-out duration-300 `}
              >
                <div className="dropdwnmain">
                <Link to="/about"
                 onClick={() =>{ setLinkActive('about')}}
                  className={linkActive === "about" 
                  ? "  no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300 dropdwn-btn linkactive"
                  : " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"
                }
                >
                  About
                </Link>
                <div className="dropdwn-bx">
                  <li onClick={() => {
                    setDrpdwnLi('Vision')
                    navigate('/about#vision')
                    }}  className={drpdwnLi === 'Vision' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/about#vision"> Vision </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Team')
                    navigate('/about#team')
                    }}  className={drpdwnLi === 'Team' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/about#team"> Team </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('R&D')
                    navigate('/about#rnd')
                    }} className={drpdwnLi === 'R&D' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                      <a to="/about#rnd"> R&D </a>
                  </li>
                </div>
                </div>
                <div className="dropdwnmain">
                <Link to="/auctions"
                  onClick={() => {setLinkActive('auctions')}}
                  className={linkActive === "auctions" ?  " dropdwn-btn linkactive no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Auctions
                </Link>
   
                <div className="dropdwn-bx">
                  <li onClick={() => {
                    setDrpdwnLi('Auction 101')
                    navigate('/auctions#auction-101')
                    }}  className={drpdwnLi === 'Auction 101' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/auctions#">Auction 101 </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Auction History')
                    navigate('/auctions#history')
                    }}  className={drpdwnLi === 'Auction History' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/auctions#history"> Auction History </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Market Size')
                    navigate('/auctions#marketsize')
                    }} className={drpdwnLi === 'Market Size' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/auctions#marketsize"> Market Size </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Auction Types')
                    navigate('/auctions#auctionTypes')
                    }}  className={drpdwnLi === 'Auction Types' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/auctions#auctionTypes" > Auction Types </a>
                  </li>
                  {/* <li onClick={() => {
                    setDrpdwnLi('Use-Cases')
                    navigate('/auctions#usecase')
                    }} className={drpdwnLi === 'Use-Cases' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/auctions#usecase"> Use-Cases </a>
                  </li> */}
                  <li onClick={() => {
                    setDrpdwnLi('DeFi Advantage')
                    navigate('/auctions#defi')
                    }} className={drpdwnLi === 'DeFi Advantage' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/auctions#defi"> DeFi Advantage  </a>
                  </li>
                  {/* <li onClick={() => {
                    setDrpdwnLi('AI in Auction')
                    navigate('/auctions#aiInAuction')
                    }} className={drpdwnLi === 'AI in Auction' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <a to="/auctions#aiInAuction"> AI in Auction </a>
                  </li> */}
                </div>
                </div>
                {/* <div className="dropdwnmain prod-dropdwn">
                  <div className="prod-link-bx">
                <Link to="#"
                  onClick={() =>{ setLinkActive('product')} } 
                  className={linkActive === "product" ?  " prod-link-btn productacitve no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " prod-link-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Products 
                </Link>
                 <div className="comn-son-text">Coming Soon</div> 
                </div>
                </div> */}


                <div className="dropdwnmain prod-dropdwn">
                <Link to="/product"
                  onClick={() =>{ setLinkActive('product')} } 
                  className={linkActive === "product" ?  " prod-link-btn  productacitve no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " prod-link-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Products 
                </Link>
   
                <div className="dropdwn-bx">
                {/* <li onClick={() => {
                    setDrpdwnLi('Reimagning')
                    navigate('/product#reimagning')
                    }}  className={drpdwnLi === 'Reimagning' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#reimagning">Reimagning </a>
                  </li> */}
                  <li onClick={() => {
                    setDrpdwnLi('DEXA Infrastructure')
                    navigate('/product#infrastructure')
                    }}  className={drpdwnLi === 'DEXA Infrastructure' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#infrastructure">DEXA Infrastructure </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('IRO Launchpad')
                    navigate('/product#Iro')
                    }}  className={drpdwnLi === 'IRO Launchpad' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#Iro"> IRO Launchpad
 </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('RDEX')
                    navigate('/product#rdex')
                    }}  className={drpdwnLi === 'RDEX' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#rdex"> RDEX
 </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Deepmatcher')
                    navigate('/product#deepmatcher')
                    }}  className={drpdwnLi === 'Deepmatcher' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#deepmatcher"> DEPIN Matcher

 </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('aiInAuction')
                    navigate('/product#aiInAuction')
                    }}  className={drpdwnLi === 'aiInAuction' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#aiInAuction"> AI In Auction


 </a>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('usecase')
                    navigate('/product#usecase')
                    }}  className={drpdwnLi === 'usecase' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <a to="/product#usecase"> Use-cases

 </a>
                  </li>
                 
                </div>
                </div>



                
                
                <div className="dropdwnmain">
                <Link to="/token"
                  onClick={() => {
                    setLinkActive('token')
                    navigate('/token')
                  }}
                  className={linkActive === "token" ?  " dropdwn-btn linkactive no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Token
                </Link>
                <div className="dropdwn-bx">
                  <li onClick={() => {
                    setDrpdwnLi('Vision')
                    navigate('/token#vision')
                    }}  className={drpdwnLi === 'Vision' ? "drpdwn-li drpdwnactive" : "drpdwn-li"} >
                    <Link to="/token#vision">Vision </Link>
                  </li>
                  <li onClick={() => {
                    setDrpdwnLi('Token Basics')
                    navigate('/token#token')
                    }}  className={drpdwnLi === 'Token Basics' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <Link to="/token#token" >Token Basics </Link>
                  </li>
                  <li onClick={() =>{
                     setDrpdwnLi('Utility')
                     navigate('/token#utility')
                     }} className={drpdwnLi === 'Utility' ? "drpdwn-li drpdwnactive" : "drpdwn-li"}>
                    <Link to="/token#utility">Utility </Link>
                  </li>
                </div>
                </div>
                <div className="dropdwnmain">
                <Link to="#"                  
                  onClick={() => {window.open("https://docs.dex.auction/dexauction/introduction",'_blank') }}
                  className={linkActive === "docs" ?  " dropdwn-btn linkactive no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Docs
                </Link>
                
                </div>
              
                <div className="dropdwnmain">
                <Link to="#"
                  onClick={() => {window.open("https://medium.com/@DexAuction",'_blank')}}
                  className={linkActive === "blog" ?  " dropdwn-btn linkactive no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Blog
                </Link>

                  </div>
                <div className="dropdwnmain">
                <Link to="/contact-us"
                  onClick={() => {setLinkActive('contact')}}
                  className={linkActive === "contact" ?  " dropdwn-btn linkactive no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300": " dropdwn-btn no-underline text-indigo-950 text-base font-medium rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"}
                >
                  Contact Us
                </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
