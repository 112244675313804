import { useLocation } from "react-router";

import AuthorizedPageCard from "../../components/ui/AuthorizedPageCard";
import Getyourworth from "./Getyourworth";
import OurTeam from "./OurTeam";
import Rnd from "./Rnd";
import Getstarted from "../Home/Getstarted";
import Subscribe from "../Home/Subscribe";
import { useEffect } from "react";

const About = () => {
  const location = useLocation()
  const activeHash = location?.hash

  useEffect(() => {
    const targetDiv = document.getElementById(activeHash?.substring(1));

    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' });
    }
  },[activeHash])
  
    return (
      <AuthorizedPageCard>
        <Getyourworth />
        <OurTeam />
        <Rnd />
        <Getstarted />
        <Subscribe />
      </AuthorizedPageCard>
    );
  };
  
  export default About;