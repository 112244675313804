import React from 'react'
import { useNavigate } from "react-router-dom";

const TokenComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="token-comp">
        <div className="common-container">

          <div className="dexa-text-img">
            <img src="/images/dexamobile.png" alt="" />
          </div>


          <div className="aisup-bg"></div>

          <div className="token-comp-info">
            <h3>Introducing the DEXA Token 
to the World.
</h3>
<h5>#GetYourWorth</h5>
          </div>

          <button onClick={() => navigate("/token")} className="token-btn" >Meet The Token</button>

          <div className="bottom-token-flex-bx">

            <div className="left-token-img-flex">
              <div className="token-img-bx token-img-bx1">
                <img src="/images/Frame-4.png" alt="" />
              </div>
              <div className="token-img-bx token-img-bx2">
                <img src="/images/Frame-3.svg" alt="" />
              </div>
            </div>


            <div className="right-token-img-flex">
            <div className="token-img-bx token-img-bx3">
                <img src="/images/Frame-2.png" alt="" />
              </div>
              <div className="token-img-bx token-img-bx4">
                <img src="/images/Frame.svg" alt="" />
              </div>
            </div>
            
          </div>
          
          
        </div>
      </section>
    </>
  )
}

export default TokenComponent
