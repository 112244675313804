import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./pages/Home/Home";
import AuthorizedPageCard from "./components/ui/AuthorizedPageCard";
import ContactUs from "./pages/ContactUs/ContactUs";
import About  from "./pages/About/About";
import Auction from "./pages/Auctions/Auctions";
import TokenDEXA from "./pages/TokenDEXA/TokenDEXA";
import Products from "./pages/Products";
function App() {
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme={"light"}
        // toastStyle={
        //   appCtx.isDarkMode
        //     ? { backgroundColor: "#27272A", color: "#E2E8F0" }
        //     : { backgroundColor: "#F8FAFC", color: "#1F2937" }
        // }
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="about" element={<About />} />
          <Route path="auctions" element={<Auction />} />
          <Route path="token" element={<TokenDEXA />} />
          <Route path="product" element={<Products />} />


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
