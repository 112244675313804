import React, { useEffect } from "react";
import "./AiSuperCharge.css";

const AiSuperCharge = () => {
  useEffect(() => {
    const boxes = document.querySelectorAll(".box-animation");

    boxes.forEach((box) => {
      const randomValues = Array.from({ length: 10 }, () => Math.random());

      randomValues.forEach((value, index) => {
        box.style.setProperty(`--random-${index + 1}`, value);
      });
    });
  }, []);
  return (
    <div className="bg-[#FFFFFF] w-full overflow-hidden " id="aiInAuction">
      <div className=" py-[10%] md:py-[5%]">
        {/* top part  */}
        <div>
       
      </div>
        <div className=" w-[90%] mx-auto flex flex-col md:flex-row justify-between items-center gap-5 md:gap-0 ">
          <div className="f-d">
        <button class=" btn-clas text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center">
         AI IN AUCTION 
        </button>
          <div className=" ai-text  font-medium text-indigo-950 text-4xl md:text-5xl w-[100%] md:w-[40%]  ">
            AI-supercharged Auctions
          </div>
          </div>
          <div className="  text-zinc-500 text-lg font-medium  w-[100%] md:w-[45%] my-5">
            Take a look at DexAuction's groundbreaking AI tools and innovations
            that harness the power of a Domain-specific LLM finetuned for
            Decentralised Auction Processes.
          </div>
        </div>
        {/* mid part  */}

        <div
          className="bg-cover bg-center relative"
          style={{
            backgroundImage: `url('/images/bannerbg.png')`,
          }}
        >
          <div className="flex w-[90%] mx-auto flex-col md:flex-row justify-between items-center text-white py-10 md:py-20">
            <div
              className="w-[100%] md:w-[30%] px-10 pt-5 pb-10 bg-[#5300CC] rounded-lg relative"
              style={{ zIndex: 2 }}
            >
              <p className="text-3xl font-bold my-[20px]">
                DEXA Autonomous Bidding Agents
              </p>
              <p className="text-base font-normal text-white text-opacity-90 ">
                Specialised Bots that can manage bidding strategies and place
                bids on your behalf to optimise capital allocation and maximise
                yield generation.
              </p>
            </div>
            <div className="relative">
              <img
                src="/images/aisupbanner.png"
                alt=""
                className="w-[380px] h-[450px] md:w-[450px] md:h-[550px]"
              />
              <div className="absolute top-[100px] left-[0px] md:top-[80px] md:left-[-300px] transform  z-40">
                {/* Render your small box images here */}
                <img
                  src="/images/box.png"
                  alt=""
                  className="w-10 h-10 mb-2 box-animation-1"
                />
                <img
                  src="/images/box2.png"
                  alt=""
                  className="w-10 h-10 mb-5 box-animation-2"
                />
              </div>
              <div className="absolute top-[300px] right-[80px] md:top-[80px] md:right-[-300px] transform  z-40">
                {/* Render your small box images here */}
                <img
                  src="/images/box.png"
                  alt=""
                  className="w-10 h-10 mb-2 box-animation-2"
                />
                <img
                  src="/images/box2.png"
                  alt=""
                  className="w-10 h-10  box-animation-1"
                />
              </div>
            </div>
            <div
              className="w-[100%] md:w-[30%] px-10 pt-5 pb-10 bg-[#5300CC] rounded-lg relative"
              style={{ zIndex: 2 }}
            >
              <p className="text-3xl font-bold my-[20px]">
                DEXA Auction Designing Copilot
              </p>
              <p className="text-base font-normal text-white text-opacity-90 ">
                Your personal auction design assistant to select, configure, and
                optimise your auctions for maximising auction participation and
                revenue.
              </p>
            </div>
          </div>
        </div>
        {/* bottom part  */}
        <div className=" w-[90%] mx-auto mt-10 flex flex-col md:flex-row justify-between gap-5 md:gap-0">
          <div className="w-[100%] md:w-[32%] h-[200px]  bg-[#EFEFEF] rounded-lg px-10 py-5 ">
            <div className=" text-indigo-950 text-xl font-bold  leading-loose">
              Fraud detection
            </div>
            <div className=" text-zinc-500 text-base font-normal  ">
              Identify shill bidding, fake bot accounts, and other fraudulent
              activities by identifying unusual bidding patterns.
            </div>
          </div>
          <div className="w-[100%] md:w-[32%] h-[200px] bg-[#EFEFEF] rounded-lg px-10 py-5 ">
            <div className=" text-indigo-950 text-xl font-bold  mb-3 ">
              Personalised Recommendations
            </div>
            <div className=" text-zinc-500 text-base font-normal  ">
              Recommend curated auctions, personalised bidding strategies, and
              send targeted promotions by user profiling.
            </div>
          </div>
          <div className="w-[100%] md:w-[32%] h-[200px]  bg-[#EFEFEF] rounded-lg px-10 py-5 ">
            <div className=" text-indigo-950 text-xl font-bold  leading-loose">
              Customer Support
            </div>
            <div className=" text-zinc-500 text-base font-normal  ">
              Handle customer inquiries and provide real-time support during
              live auctions using our Chatbots
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiSuperCharge;
