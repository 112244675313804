import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Auctioning = () => {
  const ismobile = window.screen.width <= 768;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // const rightarrow = <div></div>;

  const cardData = [
    {
      imageSrc: "/images/auction1.png",
      title: "English <br /> Auction",
      description:
        "An ascending price auction where the bid price keeps increasing starting from a reserve price till no bidder can match the highest standing bid.",
    },
    {
      imageSrc: "/images/auction2.png",
      title: "Dutch <br /> Auction",
      description:
        "A descending price auction where the auctioneer begins with a high asking price. The price decreases until one bidder is willing to pay the quoted price.",
    },
    {
      imageSrc: "/images/auction3.png",
      title: "Sealed Bid",
      description:
        "A sealed bid first price auction is where bidders submit sealed bids and the highest bidder wins and pays his bid.",
    },
    {
      imageSrc: "/images/auction4.png",
      title: "Vickrey",
      description:
        "Vickrey is a sealed bid second price auction where bidders submit sealed bids and the highest bidder wins and pays the amount of the second highest bid.",
    },
    {
      imageSrc: "/images/auction5.png",
      title: "Fixed Price <br /> Listings",
      description:
        "A bidless constant price listing at which a buyer can purchase the item immediately.",
    },
    {
      imageSrc: "/images/auction6.png",
      title: "Reverse <br /> Auction",
      description:
        "A buyer-determined auction in which many potential sellers compete to sell to a buyer.",
    },
    {
      imageSrc: "/images/auction7.png",
      title: "Penny <br /> Auction",
      description:
        "An ascending price auction where all participants pay a non-refundable bidding fee to place each incremental bid.",
    },
    {
      imageSrc: "/images/auction8.png",
      title: "Swap <br /> Auction",
      description:
        "A peer to peer exchange of two non-fungible and/or fungible token sets",
    },
    {
      imageSrc: "/images/auction9.png",
      title: "Candle <br /> Auction",
      description:
        "Candle is used as a timer to mark the end of an auction.When the candle is lit, the first bids are taken, when the candle flame finally goes out the winning bid has been locked in.",
    },
    {
      imageSrc: "/images/auction10.png",
      title: "Ranked <br /> Auction",
      description:
        "Bidders not only submit their bids but also rank their preferences for items. The auction mechanism utilizes these rankings to allocate items and determine final prices",
    },
    {
      imageSrc: "/images/auction11.png",
      title: "Double <br /> Auction",
      description:
        "Buyers and sellers propose prices for goods, with transactions executed when bid and ask prices match, facilitating efficient price determination and resource exchange",
    },
    {
      imageSrc: "/images/auction12.png",
      title: "Batch <br /> Auction",
      description:
        "Individual orders are grouped together within one batch and executed at the same time at a common clearing price.",
    },
    {
      imageSrc: "/images/auction14.png",
      title: "SMR <br /> Auction",
      description:
        "Bidding occurs license-by-license in successive rounds until a round with no further bidding is reached, making it ideal for unique items like broadcast construction permits.",
    },
  ];
  return (
    <div className="bg-[#EFEFEF]" id="auctionTypes">
      <div className="w-[90%] mx-auto py-[10%] md:py-[3%]">
        {/* top section  */}
        <div className="flex flex-row justify-between items-start">
          <button class="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center uppercase">
            AUCTION TYPES
          </button>
        </div>
        <div className="flex flex-col md:flex-row justify-between py-10">
          <h1 className="font-medium text-[#110F42] text-4xl md:text-5xl w-[100%] md:w-[45%] mb-5 md:mb-0 ">
          What auction are you looking for?
          </h1>
          <p className="w-[100%] md:w-[45%] text-zinc-500 text-lg">
          Auctions have long been a cornerstone of buying and selling, from
           high-stakes art pieces to everyday goods. Traditionally, they've 
           been categorized as open outcry (live with verbal bids), 
           sealed-bid (written submissions), or hybrid: blending both methods.
            Use cases range from fundraising events to asset liquidation, 
            often in specific industries like real estate or collectibles.
            Learn more about our suite of decentralized auction mechanisms 
            and auction types that we cater to!{" "}
          </p>
        </div>
        {/* carousel  */}
        <div className="carousel-container"></div>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          keyBoardControl={true}
          customTransition="transform 500ms ease-in"
          transitionDuration={500}
          className="mt-[2%] pb-[4%] z-10"
        >
          {cardData.map((card, index) => (
            <div className="m-3" key={index}>
              <div className="w-full md:w-[580px] h-[420px] md:h-[610px] hover:scale-105 transition-transform duration-300 flex flex-col justify-between p-[20px] md:p-[70px] bg-white rounded-md shadow-md">
                <img
                  src={card.imageSrc}
                  alt=""
                  className="w-[150px] md:w-[240px] h-[150px] md:h-[240px] ml-5 "
                />
                <div>
                  <p className="text-indigo-950 text-3xl font-medium  ">
                    <span dangerouslySetInnerHTML={{ __html: card.title }} />
                  </p>
                </div>
                <div>
                  <p className="text-zinc-500 text-base font-normal pr-0 md:pr-20">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Auctioning;
