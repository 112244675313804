import React from "react";

const Investors = () => {
  return (
    <div className="bg-white">
      <div className="w-[90%] mx-auto">
        <div className="py-[4%]">
          <button
            id="investors"
            className="text-[#5300CC] text-xl font-bold border border-[#5300CC] py-2 px-4 rounded-full inline-flex items-center"
          >
            INVESTORS AND PARTNERS
          </button>
        </div>
        <div className="py-[10%] md:py-[2%]  desktop-view">
          {/* investor small logo section  */}
          <div className="flex flex-row justify-between items-center flex-wrap gap-y-10 md:gap-y-0  ">
            <img
              src="/images/investor1.png"
              alt=""
              className="w-[152px] h-[48px]"
            />
            {/* <img
              src="/images/investor2.png"
              alt=""
              className="w-[152px] h-[27px]"
            /> */}
            <img
              src="/images/investor3.png"
              alt=""
              className="w-[124px] h-[60px]"
            />
            <img
              src="/images/investor4.png"
              alt=""
              className="w-[152px] h-[20px]"
            />
            <img
              src="/images/investor5.png"
              alt=""
              className="w-[85px] h-[60px]"
            />
            <img
              src="/images/Panoray.png"
              alt=""
              className="w-[128px] h-[50px]"
            />
            <img
              src="/images/oneether.png"
              alt=""
              className="w-[128px] h-[39px]"
            />
          </div>
          {/* investor second row section  */}
          <div className="flex flex-row justify-between items-center flex-wrap gap-y-10 md:gap-y-0 ">
            <img
              src="/images/altdrx.png"
              alt=""
              className="w-[160px] h-[80px]"
            />
            <img
              src="/images/investor8.png"
              alt=""
              className="w-[152px] h-[40px]"
            />
            <img
              src="/images/ryzer.png"
              alt=""
              className="w-[148px] h-[60px]"
            />
            <img
              src="/images/cellardao.png"
              alt=""
              className="w-[170px] h-[80px]"
            />
            
            <div className="w-full md:w-auto flex justify-center">
              <img
                src="/images/fandora.png"
                alt=""
                className="w-[140px] h-[70px] m-5 md:m-0"
              />
            </div>
           
          </div>   
          {/* <div className="flex flex-row justify-between items-center flex-wrap gap-y-10 md:gap-y-0 ">
          <img
              src="/images/investor10.png"
              alt=""
              className="w-[143px] h-[28px]"
            />
              
             <img
              src="/images/streamnft.png"
              alt=""
              className="w-[170px] h-[60px]"
            />
             <img
              src="/images/matrixedlink.png"
              alt=""
              className="w-[170px] h-[60px]"
            />
            </div> */}

         
        </div>
        <div className="img-grid py-[5%] mob-view">

<img
    src="/images/investor1.png"
    alt=""
    className="w-[152px] h-[48px] img-g-bx"
  />
  {/* <img
    src="/images/investor2.png"
    alt=""
    className="w-[152px] h-[27px]"
  /> */}
  <img
    src="/images/investor3.png"
    alt=""
    className="w-[124px] h-[60px] img-g-bx"
  />
  <img
    src="/images/investor4.png"
    alt=""
    className="w-[152px] h-[20px] img-g-bx"
  />
  <img
    src="/images/investor5.png"
    alt=""
    className="w-[85px] h-[60px] img-g-bx"
  />
  <img
    src="/images/Panoray.png"
    alt=""
    className="w-[128px] h-[50px] img-g-bx"
  />
  <img
    src="/images/oneether.png"
    alt=""
    className="w-[128px] h-[39px] img-g-bx"
  />
  
  <img
    src="/images/altdrx.png"
    alt=""
    className="w-[160px] h-[80px] img-g-bx"
  />
  <img
    src="/images/investor8.png"
    alt=""
    className="w-[152px] h-[40px] img-g-bx"
  />
  <img
    src="/images/ryzer.png"
    alt=""
    className="w-[148px] h-[60px] img-g-bx"
  />
  <img
    src="/images/cellardao.png"
    alt=""
    className="w-[170px] h-[80px] img-g-bx"
  /> 
  

 
</div>

<div className="w-full md:w-auto flex justify-center mob-view-flex">
    <img
      src="/images/fandora.png"
      alt=""
      className="w-[140px] h-[70px] img-g-bx m-5 md:m-0"
    />
  </div>
      </div>
    </div>
  );
};

export default Investors;
