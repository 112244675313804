import React from "react";
import { Link,useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>

      <footer className="footer-comp">

          <div className="foter-grid-main footer-cont">

            <div className="left-footer-bx">

              <div className="foter-logo-img-bx">
              <a
                href="/"
                aria-label="Go home"
                title="Company"
              >
                <img
                  src="/images/footerlogo.png"
                  className="foter-logo"
                  alt=""
                />
              </a>
              </div>

              <p>
              DexAuction is a pricing, trading and distribution solution
                for digital assets and tokenized RWAs, powered by decentralized
                auctions and oracle price feeds.
              </p>
              
            </div>

            <div className="right-footer-main">

              <div className="foter-list-bx">
              <button
                  onClick={() => navigate("/about")}
                >
                  About Us
                </button>
                <button
                  onClick={() =>  navigate("/auctions")}
                >
                  Auctions
                </button>
                <button
                  onClick={() =>  navigate("/products")}
                >
                  Products
                </button>
                <button
                  onClick={() =>  navigate("/token")}
                >
                  Tokens
                </button>
                <button
                >
                  Docs
                </button>
                <button
                  onClick={() =>  navigate("/blog")}
                >
                  Blog
                </button>

              </div>


              <div className="foter-grid-two-bx">

              <div className="fotr-addrs-bx">
              <span className="">DexAuction</span>
              <h6 className="">DEXAUCTION INC</h6>{" "}
              <p className="">
                Ground Floor, The Sotheby Building, Rodney Village, Rodney
                Bay,Gros Islet, Saint Lucia
              </p>
            </div>

            <div className="fotr-addrs-bx fotr-cont-bx">
              <span className="">Contact</span>
              <h6 className="">info@dex.auction</h6>{" "}
              <div className="flex items-center mt-10 gap-x-5  soc-links-flex">
                <a
                  href="https://linkedIn.com/company/dexauction"
                  target="_blank"
                >
                  <img
                    src="/images/social1.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="  https://t.me/dexauction_official" target="_blank">
                  <img
                    src="/images/social2.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a
                  href=" https://t.me/dexauction_announcements"
                  target="_blank"
                >
                  <img
                    src="/images/social3.svg"
                    alt=""
                    className="w-[40px] h-[40px] scale-125"
                  />
                </a>
                <a href="https://example.com" target="_blank">
                  <img
                    src="/images/social4.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="https://twitter.com/dexauction" target="_blank">
                  <img
                    src="/images/social5.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a
                  href=" https://www.instagram.com/dexauction/"
                  target="_blank"
                >
                  <img
                    src="/images/social6.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="https://medium.com/@DexAuction" target="_blank">
                  <img
                    src="/images/social7.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
              </div>
            </div>
            </div>
            </div>
            
          </div>

          <div className="fotr-line"></div>

          <div className="fotr-last-text-flex-bx footer-cont">

            <span>© 2023</span>

            <p>ALL RIGHTS RESERVED BY DEXAUCTION</p>
            
            <div className="policy-flex-bx">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms & Conditions</a>

            </div>
          </div>
          
      </footer>
    
      {/* <div className="bg-[#1d212d] text-white w-full">
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full  md:px-24">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-6">
            <div className="md:col-span-2">
              <a
                href="/"
                aria-label="Go home"
                title="Company"
                className="inline-flex items-center"
              >
                <img
                  src="/images/footerlogo.png"
                  className="w-full h-auto sm:max-w-xs"
                  alt=""
                />
              </a>
              <div className="mt-6">
                <p className="text-stone-200 text-opacity-90 text-xs md:text-sm">
                DexAuction is a pricing, trading and distribution solution
                for digital assets and tokenized RWAs, powered by decentralized
                auctions and oracle price feeds.
                </p>
              </div>
            </div>
            <div className="text-sm ">
              <div className="flex flex-col  gap-y-2 text-stone-200 text-lg">

                
                <button
                  onClick={() => navigate("/about")}
                  className="text-left md:text-center no-underline   "
                >
                  About 
                </button>
                <button
                  onClick={() =>  navigate("/auctions")}
                  className="text-left md:text-center no-underline  "
                >
                  Auctions
                </button>
                <button
                  onClick={() =>  navigate("/products")}
                  className="text-left md:text-center no-underline   "
                >
                  Products
                </button>
                <button
                  onClick={() =>  navigate("/token")}
                  className="text-left md:text-center no-underline   "
                >
                  Token
                </button>
                <button
                  onClick={() =>  navigate("/blog")}
                  className="text-left md:text-center no-underline  "
                >
                  Blog
                </button>
                
              </div>
            </div>

            <div className="space-y-2 text-sm">
              <p className="text-sm text-gray-500">DexAuction</p>
              <p className="mt-4 text-sm text-gray-500">DEXAUCTION INC</p>{" "}
              <p className="mt-4 text-sm text-gray-500">
                Ground Floor, The Sotheby Building, Rodney Village, Rodney
                Bay,Gros Islet, Saint Lucia
              </p>
            </div>

            <div className="col-span-1 md:col-span-2">
              <div className="space-y-2 text-sm">
                <p className="text-base font-bold ">Contacts</p>
                <p className="text-sm text-gray-500">+91 887-554-4546</p>
                <p className="mt-4 text-sm text-gray-500">
                  info@dexauction.com
                </p>
              </div>
              <div className="flex items-center mt-10 gap-x-5  ">
                <a
                  href="https://linkedIn.com/company/dexauction"
                  target="_blank"
                >
                  <img
                    src="/images/social1.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="  https://t.me/dexauction_official" target="_blank">
                  <img
                    src="/images/social2.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a
                  href=" https://t.me/dexauction_announcements"
                  target="_blank"
                >
                  <img
                    src="/images/social3.svg"
                    alt=""
                    className="w-[40px] h-[40px] scale-125"
                  />
                </a>
                <a href="https://example.com" target="_blank">
                  <img
                    src="/images/social4.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="https://twitter.com/dexauction" target="_blank">
                  <img
                    src="/images/social5.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a
                  href=" https://www.instagram.com/dexauction/"
                  target="_blank"
                >
                  <img
                    src="/images/social6.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
                <a href="https://medium.com/@DexAuction" target="_blank">
                  <img
                    src="/images/social7.svg"
                    alt=""
                    className="w-[40px] h-[40px]"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-5 pb-10 border-t mt-5 lg:flex-row">
            <p className="mb-2 text-sm text-gray-600 md:mb-0">
              ALL RIGHTS RESERVED BY DEXAUCTION
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Footer;
