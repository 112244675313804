import { useLocation } from 'react-router'
import AuthorizedPageCard from '../components/ui/AuthorizedPageCard'
import ReimagningComp from '../FaizComponents/ProductComponents/ReimagningComp'
import IroComp from '../FaizComponents/ProductComponents/IroComp'
import RdexComp from '../FaizComponents/ProductComponents/RdexComp'
import Deepmatcher from '../FaizComponents/ProductComponents/Deepmatcher'
import AiSuperCharge from './Auctions/AiSuperCharge'
import Usecase from './Auctions/Usecase'
import Getstarted from './Home/Getstarted'
import Subscribe from './Home/Subscribe'
import Networksuported from '../FaizComponents/ProductComponents/Networksuported'
import DexaInfrastructure from '../FaizComponents/ProductComponents/DexaInfrastructure'
import { useEffect } from 'react'

const Products = () => {
    const location = useLocation()
    const activeHash = location?.hash
  
    useEffect(() => {
      const targetDiv = document.getElementById(activeHash?.substring(1));
  
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' });
      }
    },[activeHash])
   
  return (
    <>
      <AuthorizedPageCard>
     <ReimagningComp />
     <DexaInfrastructure />
     <IroComp />
     <RdexComp />
     <Deepmatcher />
     <AiSuperCharge />
     <Usecase />
     <Networksuported />
     <Getstarted />
        <Subscribe />
     </AuthorizedPageCard>
    

    </>
  )
}

export default Products
